<template>
  <v-main
    id="pages"
    :class="$vuetify.theme.dark ? undefined : 'grey lighten-1'"
  >
    <router-view />
  </v-main>
</template>

<script>
  export default {
    name: 'PagesCoreView',

    data: () => ({
      srcs: {
        '/pages/login': 'login.jpg'
      }
    }),

    computed: {
      src() {
        return this.srcs[this.$route.path]
      }
    }
  }
</script>

<style lang="sass">
  #pages
    > .v-main__wrap > .v-image
      padding-top: 64px
      padding-bottom: 88px

    .v-responsive__sizer
      display: block

    .v-image--md
      padding: 188px 0

    .v-image--sm
      padding: 96px 0 188px 0
</style>
